import {
  Dashboard,
  Clipboard,
  Tools,
  Chart,
  Customer,
  Medal,
  Logs,
  Broadcast,
  Whatsapp,
} from "assets/svg";

export const superadminConfig = {
  menus: [
    {
      key: "/admin/dashboard",
      title: "Dashboard",
      icon: Dashboard,
      component: "Dashboard",
    },
    { key: "/admin/merchants", title: "Merchants", component: "ViewMerchants" },
    {
      key: "/admin/halfway-merchants",
      title: "Halfway Merchants",
      component: "HalfwayMerchants",
    },
    {
      key: "/admin/removed-merchants",
      title: "Leads Merchants",
      component: "ViewRemovedMerchants",
    },
    {
      key: "/admin/customer-management",
      title: "Customer Managerment",
      component: "CustomerManagement",
    },
    {
      key: "/admin/earnings-mamagement",
      title: "Earnings Managerment",
      component: "EarningsManagement",
    },
    {
      key: "/admin/business-request",
      title: "Business Request",
      component: "BusinessRequest",
    },
    // { key: '/admin/affiliate-submissions', title: 'Affiliate Submissions', component: 'AffiliateSubmissions' },
  ],
  others: [],
};

export const adminConfig = {
  menus: [
    {
      key: "/admin/dashboard",
      title: "Dashboard",
      icon: Dashboard,
      component: "Dashboard",
    },
    {
      key: "/admin/account",
      title: "Campaign Page",
      icon: Clipboard,
      subs: [
        {
          key: "/admin/account/details",
          title: "Edit Password",
          component: "Account",
          hide: true,
        },
        {
          key: "/admin/merchant/merchantBrand",
          title: "Appearance",
          component: "MerchantBrand",
        },
        {
          key: "/admin/merchant/merchantDetails",
          title: "Business Details",
          component: "MerchantDetails",
        },
        {
          key: "/admin/billing",
          title: "Billing / Invoices",
          component: "Invoices",
          hide: true,
        },
        {
          key: "/admin/merchant/outlet",
          title: "Outlet Location",
          component: "Outlet",
        },
        {
          key: "/admin/merchant/seo",
          title: "Social Sharing",
          component: "SEO",
        },
        {
          key: "/admin/merchant/business",
          title: "Business Verification",
          component: "Business",
          tick: true,
        },
        {
          key: "/admin/merchant/qr",
          title: "Poster & QR",
          component: "QRCode",
        },
      ],
    },
    {
      key: "/admin/tools",
      title: "Campaign Tools",
      icon: Tools,
      subs: [
        {
          key: "/admin/tools/all",
          title: "All",
          component: "All",
          query: { name: "all" },
        },
        {
          key: "/admin/tools/stamp",
          title: "Stamp Card",
          component: "Stamp",
          query: { name: "stamp" },
        },
        {
          key: "/admin/tools/package",
          title: "Package",
          component: "Package",
          query: { name: "package" },
        },
        {
          key: "/admin/tools/referral",
          title: "Referral",
          component: "Referral",
          query: { name: "referral" },
        },
        {
          key: "/admin/tools/voucher",
          title: "Voucher",
          component: "Voucher",
          query: { name: "voucher" },
        },
        {
          key: "/admin/tools/points",
          title: "Points",
          component: "Points",
          query: { name: "points" },
        },
        {
          key: "/admin/tools/wallet",
          title: "Wallet",
          component: "Wallet",
          query: { name: "wallet" },
        },
        {
          key: "/admin/tools/modeofpayment",
          title: "Payment Mode",
          component: "ModeOfPayment",
          query: { name: "modeOfPayment" },
        },
        { key: "/admin/tools/rep", title: "MRZScan", component: "MerchantRep" },
        {
          key: "/admin/tools/gasetup",
          title: "Google Analytics Setup",
          component: "GASetup",
          query: { name: "gaSetup" },
        },
        {
          key: "/admin/tools/campaignexpiry",
          title: "Campaign Reminder",
          component: "CampaignExpiry",
          query: { name: "campaignExpiry" },
        },
      ],
    },
    {
      key: "/admin/marketingBroadcast",
      title: "Marketing Broadcast",
      icon: Broadcast,
      subs: [
        {
          key: "/admin/marketing/sendingBroadcast",
          title: "Send Broadcast",
          component: "SendBroadcast",
          query: { name: "sendBroadcast" },
        },
        {
          key: "/admin/marketing/broadcastHistory",
          title: "Broadcast History",
          component: "BroadcastHistory",
          query: { name: "broadcastHistory" },
        },
      ],
    },
    {
      key: "/admin/device",
      title: "Whatsapp",
      icon: Whatsapp,
      component: "MerchantDevice",
    },
    {
      key: "/admin/scanlogs",
      title: "Scan Logs",
      icon: Logs,
      component: "ScanLogs",
    },
    {
      key: "/admin/customers",
      title: "Customers",
      icon: Customer,
      component: "CustomerManagement",
    },
    {
      key: "/admin/pointsManagement",
      title: "Points",
      icon: Medal,
      component: "PointsSubmissions",
      query: { name: "points" },
    },
    {
      key: "/admin/transactions",
      title: "Reports",
      icon: Chart,
      component: "Transactions",
    },
    // { key: '/admin/affiliate', title: 'Affiliate Program', icon: 'share-alt', component: 'Affiliate' },

    // { key: '/admin/report', title: 'Merchant Report', icon: 'profile', component: 'AdminDevelopment' },
    {
      key: "/admin/upgrade",
      title: "Upgrade Plan",
      icon: "crown",
      component: "UpgradePlan",
      hide: true,
    },
    {
      key: "/admin/topup",
      title: "Topup",
      icon: "crown",
      component: "Topup",
      hide: true,
    },
    {
      key: "/admin/earnings",
      title: "Earnings",
      icon: "crown",
      component: "Earnings",
      hide: true,
    },
    {
      key: "/admin/businessProfile",
      title: "Business Profile",
      icon: "crown",
      component: "BusinessProfile",
      hide: true,
    },
    {
      key: "/admin/support",
      title: "Support",
      icon: "bulb",
      component: "AdminDevelopment",
      hide: true,
    },
    {
      key: "/admin/notification",
      title: "Notification",
      icon: "bulb",
      component: "MerchantNotifications",
      hide: true,
    },
  ],
  others: [],
};
