import React, { useState, useContext, useEffect } from "react";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import { DataContext } from "contexts/DataContextContainer";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ListGroup from "react-bootstrap/ListGroup";
import ModalConfirmation from "components/Extra/ModalConfirmation";
import StyledButton from "components/Elements/StyledButton";

import client from "feathers.js";
import axios from "axios";
import { cloneDeep } from "lodash";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { Lock } from "assets/svg";

const BusinessSchema = Yup.object().shape({
  stripePublishableKey: Yup.string().required("Required!"),
  stripeSecretKey: Yup.string().required("Required!"),
  // stripePublishableKey: Yup.string().required("Required!"),
  // stripeSecretKey: Yup.string().required("Required!"),
});

const Wallet = (props) => {
  // const { title } = props
  const history = useHistory();
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [campaignData, setCampaignData] = useState({});
  const [modePayment, setModePayment] = useState({
    cash: true,
    wallet: false,
    credit: false,
  });
  const [modePaymentPackage, setModePaymentPackage] = useState({
    cash: true,
    wallet: false,
    credit: false,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleUnlink, setModalVisibleUnlink] = useState(false);
  const [stripeValid, setStripeValid] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    setLoading(true);
  };

  const toggleModalUnlink = () => {
    setModalVisibleUnlink(!modalVisibleUnlink);
  };

  const modalCallbackUnlink = () => {
    setLoading(true);

    let modePayment = {
      cash: true,
      wallet: false,
      credit: false,
    };
    let modePaymentPackage = {
      cash: true,
      wallet: false,
      credit: false,
    };

    let value = {
      stripePublishableKey: "",
      stripeSecretKey: "",
      modePayment,
      modePaymentPackage,
    };

    if (!campaignData._id) {
      showToast("error", "No Record found!");
      return false;
    }

    client
      .authenticate()
      .then(() => {
        return client.service("wallets").patch(campaignData._id, value);
      })
      .then((res) => {
        setLoading(false);
        setCampaignData(res);
        setModePayment(modePayment);
        setModePaymentPackage(modePaymentPackage);
        showToast("success", "Edit Successful!");
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
        setLoading(false);
      });
  };

  const getCampaignData = () => {
    setLoading(true);
    client
      .authenticate()
      .then(() => {
        return client.service("wallets").find({
          query: {
            userId: props.userInfo._id,
            $limit: 1,
          },
        });
      })
      .then((res) => {
        // Is Edit
        if (res.data.length > 0) {
          setCampaignData(res.data[0]);
          setModePayment(res.data[0].modePayment);
          setModePaymentPackage(res.data[0].modePaymentPackage);
          setLoading(false);
        } else {
          // Is Create
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
        // No stop loading
        // setLoading(false)
      });
  };

  const handleSubmit = (value) => {
    // value.modePayment = modePayment
    // value.modePaymentPackage = modePaymentPackage

    setLoading(true);
    if (campaignData._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("wallets").patch(campaignData._id, value);
        })
        .then((res) => {
          setLoading(false);
          toggleModal();
          setCampaignData(res);
          showToast("success", "Edit Successful!");
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
          setLoading(false);
        });
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("wallets").create(value);
        })
        .then((res) => {
          setCampaignData(res);
          setLoading(false);
          toggleModal();
          showToast("success", "Edit Successful!");
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
          setLoading(false);
        });
    }
  };

  const handleUpdate = () => {
    if (!modePayment.cash && !modePayment.wallet && !modePayment.credit) {
      showToast("error", "Please select atleast one payment option!");
      return false;
    }
    if (
      !modePaymentPackage.cash &&
      !modePaymentPackage.wallet &&
      !modePaymentPackage.credit
    ) {
      showToast("error", "Please select atleast one payment option!");
      return false;
    }

    let value = {};
    value.modePayment = modePayment;
    value.modePaymentPackage = modePaymentPackage;

    setLoading(true);
    if (campaignData._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("wallets").patch(campaignData._id, value);
        })
        .then((res) => {
          setLoading(false);
          showToast("success", "Edit Successful!");
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
          setLoading(false);
        });
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("wallets").create(value);
        })
        .then((res) => {
          setCampaignData(res);
          setLoading(false);
          showToast("success", "Created Successful!");
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
          setLoading(false);
        });
    }
  };

  const onChange = (mode, type, value) => {
    if (mode === "wallet") {
      let cloneModePayment = cloneDeep(modePayment);
      cloneModePayment[type] = value;
      setModePayment(cloneModePayment);
    } else {
      let cloneModePaymentPackage = cloneDeep(modePaymentPackage);
      cloneModePaymentPackage[type] = value;
      setModePaymentPackage(cloneModePaymentPackage);
    }
  };

  const testConnect = (stripePublishableKey, stripeSecretKey) => {
    setLoading(true);

    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}check-stripe-key`,
          {
            stripePublishableKey,
            stripeSecretKey,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        if (res.data === "success") {
          setStripeValid(true);
          showToast("success", "Valid Keys! Connected to stripe successfully");
        } else {
          setStripeValid(false);
          showToast("error", "Invalid Keys! Fail to connect to stripe!");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", "Invalid Keys!");
        setStripeValid(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    if (props.userInfo.business_verified) {
      getCampaignData();
    } else {
      // Submit Form
    }
    /* eslint-disable-next-line */
  }, [props.userInfo._id, props.userInfo.business_verified]);

  return (
    <PageContainer>
      <InfoCard desc='The payment modes on your campaign page serve to specify the accepted forms of payment for transactions within your campaign' />

      <div className='page-header'>Accepted payment modes</div>

      <DetailsSection
        leftHeader='Wallet Top Up'
        leftDesc='Select accepted payment modes for customers to top up their wallets.'
        rightContent={
          <>
            <div className='options'>
              <div className='title-wrapper'>
                <div className='stripe-text'>stripe</div>
                <div className='stripe-setup'>
                  Credit Card (stripe)
                  <button
                    type='button'
                    className='setup-text'
                    onClick={() => toggleModal()}
                  >
                    {(campaignData.stripePublishableKey &&
                    campaignData.stripeSecretKey)
                      ? "Manage"
                      : "Set up >"}
                  </button>
                </div>
              </div>
              <div className='toggle-wrapper'>
                {(campaignData.stripePublishableKey &&
                  campaignData.stripeSecretKey) && (
                    <Switch
                      checked={modePayment.credit || false}
                      onChange={(e) => onChange("wallet", "credit", e)}
                    />
                  )}
              </div>
            </div>
            <div className='options'>
              Cash payment (over the counter)
              <div className='toggle-wrapper'>
                <Switch
                  checked={modePayment.cash || false}
                  onChange={(e) => onChange("wallet", "cash", e)}
                />
              </div>
            </div>
          </>
        }
      />

      <DetailsSection
        leftHeader='Package Purchases'
        leftDesc='Select accepted payment modes for customers to purchase packages.'
        rightContent={
          <>
            <div
              className={
                props.userInfo.subscription === "copper"
                  ? "options-disabled"
                  : "options"
              }
            >
              <div className='disabled-text-wrapper'>
                Wallet
                {props.userInfo.subscription === "copper" && (
                  <div className='disabled-text'>
                    <br />
                    Bronze member and above only. Upgrade now
                  </div>
                )}
              </div>
              <div className='toggle-wrapper'>
                <Switch
                  checked={modePaymentPackage.wallet || false}
                  onChange={(e) => onChange("package", "wallet", e)}
                  disabled={
                    props.userInfo.subscription === "copper" ? true : false
                  }
                />
              </div>
            </div>
            <div className='options'>
              <div className='title-wrapper'>
                <div className='stripe-text'>stripe</div>
                <div className='stripe-setup'>
                  Credit Card (stripe)
                  <button
                    type='button'
                    className='setup-text'
                    onClick={() => toggleModal()}
                  >
                    {campaignData.stripePublishableKey &&
                    campaignData.stripeSecretKey
                      ? "Manage"
                      : "Set up >"}
                  </button>
                </div>
              </div>
              <div className='toggle-wrapper'>
                {(campaignData.stripePublishableKey &&
                  campaignData.stripeSecretKey) && (
                    <Switch
                      checked={modePaymentPackage.credit || false}
                      onChange={(e) => onChange("package", "credit", e)}
                    />
                  )}
              </div>
            </div>
            <div className='options'>
              Cash payment (over the counter)
              <div className='toggle-wrapper'>
                <Switch
                  checked={modePaymentPackage.cash || false}
                  onChange={(e) => onChange("package", "cash", e)}
                />
              </div>
            </div>
          </>
        }
      />

      <div className='button-wrapper'>
        <StyledButton
          title={
            <div className='button-text'>
              Update
              {props.userInfo.subscription === "copper" && (
                <img
                  src={Lock}
                  alt=''
                  width='24px'
                  height='24px'
                  className='icon'
                />
              )}
            </div>
          }
          sizeh={48}
          className={
            props.userInfo.subscription === "copper"
              ? "update-button-disabled"
              : "update-button"
          }
          bgcolor={"#567877"}
          onClick={handleUpdate}
          disabled={props.userInfo.subscription === "copper" ? true : false}
        />
      </div>

      <ModalConfirmation
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <br />
            {"Stripe Setup"}
          </>
        }
        body={
          <>
            <Formik
              initialValues={{
                stripePublishableKey: campaignData.stripePublishableKey
                  ? campaignData.stripePublishableKey
                  : "",
                stripeSecretKey: campaignData.stripeSecretKey
                  ? campaignData.stripeSecretKey
                  : "",
              }}
              enableReinitialize
              validationSchema={BusinessSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "400px",
                    gap: "25px",
                    margin: "25px 0 0 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    Stripe Publishable Key
                    <Field
                      type='text'
                      className='form-control'
                      name='stripePublishableKey'
                      placeholder='Stripe Publishable Key...'
                    />
                    <ErrorMessage name='stripePublishableKey' />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    Stripe Secret Key
                    <Field
                      type='text'
                      className='form-control'
                      name='stripeSecretKey'
                      placeholder='Stripe Secret Key...'
                    />
                    <ErrorMessage name='stripeSecretKey' />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <a
                      href='https://morerewardz.com/Set-up-Stripe/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      (See how to get Stripe keys?)
                    </a>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      type='button'
                      onClick={() =>
                        testConnect(
                          values.stripePublishableKey,
                          values.stripeSecretKey
                        )
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #feb02d",
                        background: "white",
                        borderRadius: "10px",
                        height: "48px",
                        width: "160px",
                        padding: "12px 24px",
                        fontSize: "14px",
                        color: "#feb02d",
                        cursor: "pointer",
                      }}
                    >
                      Test connect
                    </button>
                    <button
                      type='submit'
                      disabled={!stripeValid}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        background: "#567877",
                        borderRadius: "10px",
                        height: "48px",
                        width: "160px",
                        padding: "12px 24px",
                        fontSize: "14px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        }
        noFooter={true}
      />

      <ModalConfirmation
        modalVisible={modalVisibleUnlink}
        toggleModal={toggleModalUnlink}
        modalCallback={modalCallbackUnlink}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {"Unlink Stripe"}
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>Proceed to Unlink Stripe?</span>
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
