import React, { useContext, useEffect } from "react";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import StyledButton from "components/Elements/StyledButton";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateDetails } from "redux/actions/roleActions";
import { AccessDenied } from "assets/images";

import client from "feathers.js";
import DetailsSection from "components/DetailsSection/RowContainer";
import { Lock } from "assets/svg";

const WalletSchema = Yup.object().shape({
  wablas_domain: Yup.string().required("Required!"),
  wablas_token: Yup.string().required("Required!"),
});

const Business = (props) => {
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);

  const handleSubmitWallet = async (value, { resetForm }) => {

    setLoading(true);

    client.authenticate()
    .then(() => {
      return client
        .service("merchants")
        .patch(props.userId, value);
    })
    .then((res) => {
      props.updateDetails(res);
      setLoading(false);
      showToast("success", "Info updated Successful!");
    })
    .catch((err) => {
      console.log(err);
      showToast("error", err.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  return (
    <PageContainer style={{ position: "relative" }}>

      {props.userInfo.subscription === "copper" ? (
          <div className='access-denied'>
            <div>
              <h1>OOPS!</h1>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '1000px'}}>
                <p>You don’t have access to this feature.</p>
                <p>
                  This feature is exclusively available for our Bronze tier and
                  above members. Upgrade your membership today to access this
                  exciting feature and enjoy even more perks and privileges.
                </p>
              </div>
              <img src={AccessDenied} alt='' width={700} />
              <button
                className='btn-upgrade'
                onClick={() => props.history.push("/admin/upgrade")}
              >
                Upgrade Now!
              </button>
            </div>
          </div>
        ):
        <DetailsSection
          leftHeader='Wablas'
          leftDesc='Provide your Wablas details here. These details will be displayed on your campaign page for customers to reach out directly if needed.'
          rightContent={
            <Formik
              initialValues={{
                wablas_domain: props.userInfo.wablas_domain ? props.userInfo.wablas_domain : "",
                wablas_token: props.userInfo.wablas_token ? props.userInfo.wablas_token : "",
              }}
              enableReinitialize
              validationSchema={WalletSchema}
              onSubmit={handleSubmitWallet}
            >
              {({ values }) => (
                <Form className='form-styling'>
                  <div className='input-container'>
                    Domain
                    <Field
                      type='text'
                      className='input'
                      name='wablas_domain'
                      placeholder='https://wablas.com'
                    />
                    <div className='error-message'>
                      <ErrorMessage name='wablas_domain' />
                    </div>
                  </div>
                  <div className='input-container'>
                    API Keys / Token
                    <Field
                      type='text'
                      className='input'
                      name='wablas_token'
                      placeholder='4dxNKcISjSBCBGwccQvyIkYRd90NuWgm7s2h3cL9FXXRAvfQ7V8Wm57WvBtQLNIx'
                    />
                    <div className='error-message'>
                      <ErrorMessage name='wablas_token' />
                    </div>
                  </div>

                  <div className='row-container'>
                    <StyledButton
                      title={
                        <>
                          <div className='button-text'>
                            Confirm
                            {props.userInfo.subscription === "copper" && (
                              <div className='button-icon-wrapper'>
                                <img
                                  src={Lock}
                                  alt=''
                                  width='24px'
                                  height='24px'
                                />
                              </div>
                            )}
                          </div>
                        </>
                      }
                      sizeh={44}
                      type='submit'
                      className={
                        props.userInfo.subscription === "copper"
                          ? "confirm-button-disabled"
                          : "confirm-button"
                      }
                      radius='7'
                      bgcolor={"#567877"}
                      disabled={
                        props.userInfo.subscription === "copper" ? true : false
                      }
                    />
                  </div>
                </Form>
              )}
            </Formik>
          }
        />
      }
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
